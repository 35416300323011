import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PapiDeviation } from '@wix/da-papi-types';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { useFlag } from '@wix/da-react-context/pkg/flags/hooks';
import ShopThumb, {
  ShopThumbType,
} from '@wix/da-shared-react/pkg/DeviationViews/Thumb/Shop';
import {
  BiContextProvidedValue,
  BiData,
  ShowLessLikeThisBiEvent,
  WatchItemRemoveClickBiEvent,
} from '@wix/da-bi/pkg/events';
import StandardThumb from '@wix/da-shared-react/pkg/DeviationViews/Thumb/Standard';
import MobileThumb from '@wix/da-shared-react/pkg/DeviationViews/Thumb/Mobile';
import { getDeviationResourceType } from '@wix/da-shared-react/pkg/DeviationViews/_helpers';
import DeviationMediaService from '@wix/da-shared-react/pkg/utils/DeviationMediaService';
import { DeviationTypes } from '@wix/da-shared-react/pkg/types/deviation';
import { NullableDropdownItem } from '@wix/da-ds/pkg/Dropdown/types';
import { Props as ItemCardProps } from './ItemCard';

export interface Props
  extends Pick<
    ItemCardProps,
    | 'width'
    | 'height'
    | 'style'
    | 'canShowLess'
    | 'responsive'
    | 'onShowLess'
    | 'showTierPromotionTooltip'
    | 'onRemove'
  > {
  deviation: PapiDeviation;
  offset: number;
  reasonShown?: string;
  allowMobileCard?: boolean;
  className?: string;
}
const nonDeviationTypes = [
  DeviationTypes.COMMISSION,
  DeviationTypes.POLL,
  DeviationTypes.STATUS,
  DeviationTypes.UNKNOWN,
];

const DeviationCard: React.FC<Props> = ({
  deviation,
  offset,
  width,
  height,
  style,
  responsive,
  canShowLess,
  showTierPromotionTooltip,
  className,
  reasonShown = 'unknown',
  allowMobileCard,
  onRemove,
  onShowLess,
}) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const rename_llt_to_x = useFlag('rename_llt_to_x');

  /** Desktop keeps the remove button outside the actions menu */
  const shouldPutRemoveButtonInActionsMenu = !rename_llt_to_x && isMobile;
  const devType = DeviationMediaService.getDeviationType(deviation);
  const hasShowLess = canShowLess && !nonDeviationTypes.includes(devType);
  const actions: NullableDropdownItem[] = useMemo(
    () => [
      hasShowLess && {
        label: t(rename_llt_to_x
          ? 'itemCard.remove'
          : 'itemCard.showLess'
        ),
        onClick: () =>
          onShowLess({
            deviationId: deviation.deviationId,
            offset,
            typeId: getDeviationResourceType(deviation),
            reason: reasonShown,
            ownerId: deviation.author?.userId,
          }),
        biData: BiData<ShowLessLikeThisBiEvent>({
          evid: 110,
          creatorid: deviation.author?.useridUuid ?? '',
          typeid: getDeviationResourceType(deviation),
          itemid: deviation.deviationId,
          rank: BiContextProvidedValue,
          reason_shown: BiContextProvidedValue,
          sectionname: BiContextProvidedValue,
          section_typeid: BiContextProvidedValue,
          sectionid: BiContextProvidedValue,
          sort_type: BiContextProvidedValue,
        }),
      },
      shouldPutRemoveButtonInActionsMenu &&
        onRemove && {
          label: t('itemCard.remove'),
          onClick: () => onRemove(deviation, offset),
          biData: BiData<WatchItemRemoveClickBiEvent>({
            evid: 405,
            typeid: getDeviationResourceType(deviation),
            itemid: deviation.deviationId,
            items_selected_count: 1,
            rank: BiContextProvidedValue,
            sectionname: BiContextProvidedValue,
            sectionid: BiContextProvidedValue,
            section_typeid: BiContextProvidedValue,
            is_grouped: 0,
          }),
        },
    ],
    [
      hasShowLess,
      t,
      deviation,
      onRemove,
      onShowLess,
      offset,
      reasonShown,
      shouldPutRemoveButtonInActionsMenu,
    ]
  );
  const onRemoveItem = useMemo(
    () => onRemove && (() => onRemove(deviation, offset)),
    [deviation, onRemove, offset]
  );

  if (devType === DeviationTypes.COMMISSION) {
    return (
      <ShopThumb
        deviation={deviation}
        width={width}
        height={height}
        type={ShopThumbType.COMMISSION}
        style={style}
        className={className}
      />
    );
  }

  if (isMobile && allowMobileCard) {
    return (
      <MobileThumb
        deviation={deviation}
        width={width}
        height={height}
        style={style}
        responsive={responsive}
        extraActions={actions}
        showTierPromotionTooltip={showTierPromotionTooltip}
        className={className}
      />
    );
  }

  return (
    <StandardThumb
      deviation={deviation}
      width={width}
      height={height}
      style={style}
      responsive={responsive}
      extraActions={actions}
      showTierPromotionTooltip={showTierPromotionTooltip}
      onRemove={shouldPutRemoveButtonInActionsMenu ? undefined : onRemoveItem}
      className={className}
    />
  );
};
DeviationCard.displayName = 'DeviationCard';

export default DeviationCard;
